import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      <SEO
        keywords={[`hackguard`, `cyber security`, `contact us`]}
        title="Contact"
      />
      <section className="flex flex-col items-center my-8 md:flex-row">
        <p className="md:w-2/3 md:mr-8 text-bold text-3xl justify-center items-center">
          Ready to imporve your security stance? Here is how to reach us.
        </p>
      </section>
      <section className="flex flex-col justify-center items-center my-8 md:flex-row">
        <div className="md:w-2/3 md:mr-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            viewBox="0 0 20 20"
            fill="#00796B"
          >
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <p className="my-8 md:w-2/3 md:mr-8">+249999973917</p>
        </div>
        <div className="md:w-2/3 md:mr-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8"
            viewBox="0 0 20 20"
            fill="#00796B"
          >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg>
          <p className="my-8 md:w-2/3 md:mr-8">info@hackguard.io</p>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
